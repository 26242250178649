import { DashBoard } from "../components/nlp/DashBoard";
import { AIHero } from "../components/nlp/AIHero";
import { DashboardEval } from "../components/nlp/Evaluation";

export const NaturalLanguageProcessing = () => {

    return (
        <>
            <DashBoard/>
            <AIHero/>
            <DashboardEval/>
        </>
    );
};