import React from "react";
import { Sidebar } from "./CelebritySidebar.jsx";
import { CodeBlock } from "./CodeBlock.jsx";
import { codemodel_1, codemodel_2, codepairs, codetrainmodel, loss_func, codeprecrecall, codeevalmodel } from "../../data/code_snippets.js"

export const CelebrityAbout = () => {
    
    return (
        <>
        <section  id = 'celebrity-about' className="celebrity-about">
            <Sidebar></Sidebar>
            <div className="cv-content-wrapper">
                <div className="cv-container">
                    <div className="cv-container-about">
                        <h1 id="what-face-recog">
                            What is face recognition?
                        </h1>
                        <div className="cv-content-about">
                            <h2 id="goal">
                                Goal:
                            </h2>
                            <p>
                                Face recognition is the task if identifying a person from a set of ground-truth images based on a given facial image.
                            </p>
                            <h2 id="process">
                                Process:
                            </h2>
                            <ol className="process-face-recognition">
                                <li>
                                    <strong>Ground-truth:</strong> Known faces are stored in a database for recognition task.
                                </li>
                                <li>
                                    <strong>Input:</strong> The features of a given input image are extracted.
                                </li>
                                <li>
                                <strong>Comparison:</strong> It compares the features of the given input against features of ground-truth faces in the database.
                                </li>
                                <li>
                                    <strong>Recognition:</strong> The system assigns an identity based on the closest match. 
                                </li>
                            </ol>
                            <h1 id="what-siamese-model">
                                What is a Siamese model?
                            </h1>
                            <p>
                                A Siamese neural network is a type of neural network architecture that is designed to learn a similarity function between two given 
                                inputs.
                                <br></br> 
                            </p>
                            <h2 id="structure">
                                Structure
                            </h2>
                            <ol className="structure-siamese-model">
                                <li>
                                    <strong>Tower Networks:</strong> The Siamese network consists of two identical subnetworks often called
                                    "often called twin networks". Each tower processes each input item, while sharing weights.
                                    <br></br>
                                    They are shared to ensure that both inputs are passed through the same similarity function. 
                                </li>
                                <li>
                                    <strong>Feature Extraction:</strong> The network extracts features of the input item. The architecture for the twin networks can be any
                                    type of neural network. <br></br> Convolutional layers with pooling layers for images or recurrent NN's for sequential data. 
                                </li>
                                <li>
                                    <strong>Distance layer:</strong> Each of these twin networks output an n-dimensional embedding vector for each of the inputs. 
                                    Now we can define a distance function to compare the embeddings. 
                                    <ul>
                                        <li>
                                            <strong>Euclidean distance:</strong> Measures the straight-line distance between the two vectors. 
                                        </li>
                                        <li>
                                            <strong>Cosine similarity:</strong> Measures the cosine of the angle between the two vectors. 
                                        </li>
                                        <li>
                                            <strong>Manhattan distance:</strong> Measures sum of the absolute difference between the coordinates of the two vectors. 
                                        </li>
                                    </ul> 
                                </li>
                                <li>
                                    <strong>Output layer:</strong> Now that the network has calculated a distance between the inputs, we can leave as is and interpret the values to determine
                                    similarity. Or, we can treat this as a binary classification problem and feed this into a sigmoid layer.
                                </li>
                            </ol>
                            <div className="cv-content-about-image-1"></div> 
                                <h1 id="training">Training</h1>
                            
                                <p>
                                    The model will be trained to differentiate between faces of different classes.
                                    For example, the face of Ronaldo needs to be differentiated for all other different faces (classes).
                                </p>
                                <h2 id="input-pairs">
                                    Input pairs
                                </h2>
                                <p>
                                    During training, the model is fed pairs of images with a label indicating whether the pairs are similar (0.0) or dissimilar (1.0).
                                </p>
                                <div className="code">
                                    <CodeBlock code={ codepairs }></CodeBlock>
                                </div>
                                <h2 id="inspect-pairs">Inspect pairs of images</h2>
                                <div className="cv-content-about-image-3"></div>  
                                <h2 id="loss-func">
                                    Loss function
                                </h2>
                                <p>
                                    The model is trained using a loss function that motivates the network to produce similar embedding vectors for similar inputs and dissimilar
                                    embeddings for dissimilar inputs. We will consider the contrastive loss function:
                                </p>
                                <ul className="training-lossfunction">
                                    <li>
                                        <strong>Contrastive loss:</strong> Combines the distances between pairs with the labels indicating similarity. It aims to 
                                        minimise the distnce for similar pairs and maximise distance for dissimilar pairs.
                                    </li>
                                </ul>
                                <div className="code">
                                    <CodeBlock code={ loss_func }></CodeBlock>
                                </div>
                                <h1 id="siamese-model">Siamese Model</h1>
                                <div className="code">
                                    <CodeBlock code={ codemodel_1 }></CodeBlock>
                                </div>
                                <div className="code">
                                    <CodeBlock code={ codemodel_2 }></CodeBlock>
                                </div> 
                                <h2 id="train-model">Train model</h2>
                                <div className="code">
                                    <CodeBlock code={ codetrainmodel }></CodeBlock>
                                </div>
                                <h2 id="visualise">
                                    Visualise results
                                </h2>
                                <div className="cv-content-about-image-5"></div>
                                <h1 id="evaluation">
                                    Evaluation
                                </h1> 
                                <p>
                                    Once we have trained the model, we can evaluate on data the model has not seen. 
                                </p>
                                <h2 id="prec-recall">Precision and Recall</h2>
                                <ul className="prec">
                                    <li>
                                        <strong>Precision:</strong> The ratio of true positive predictions to the total predicted positives,
                                        meaning how many selected items are relevent.
                                    </li>
                                    <li>
                                        <strong>Recall:</strong> The ratio of true positive predictions to the total actual positives, 
                                        meaning how many relevent items are selected.
                                    </li>
                                </ul>
                                <div className="code">
                                    <CodeBlock code={ codeprecrecall }></CodeBlock>
                                </div>
                                <div className="code">
                                    <CodeBlock code={ codeevalmodel }></CodeBlock>
                                </div>
                                <div className="code">
                                    <pre className="output-code">
                                        <code data-highlighted='no'>Precision result: 0.9166666865348816 <br></br>Recall result: 0.9312169551849365
                                            </code>
                                    </pre>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
