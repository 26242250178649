import React from "react";
import { Box } from '@mui/material';
import AboutImage from '../../images/about-image3.jpg';

export const About = () => {
    return (
        <>
            <section style={{display: 'flex'}} id="about" href = '#about'>
                <Box sx={{margin: '0 auto', maxWidth: '80rem', padding: '10rem 0 10rem 0'}}>
                    <Box sx={{display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr 1fr', justifyContent: 'center', gap: '4rem'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <h3 style={{paddingBottom: '1rem', letterSpacing: '0.05rem', fontWeight: '700', fontSize:"1.2rem", color: '#555555', textTransform: 'uppercase'}}>About me</h3>
                            <h4 style={{color: '#2d2e32', fontSize: '2.5rem', lineHeight: 1.5}}>MSc Data Science at University of Bath</h4>
                            <p style={{letterSpacing: '0.05rem', fontWeight: '300', lineHeight: 1.5, color: '#000000', fontSize: '1.2rem'}}>I'm Roshan, currently pursuing an MSc in Data Science at the University of Bath. 
                                My passion lies in solving complex problems and deriving insights from large datasets using AI and machine learning.</p>
                            <p style={{letterSpacing: '0.05rem', fontWeight: '300', lineHeight: 1.5, color: '#000000', fontSize: '1.2rem', paddingTop:'1rem'}}>I enjoy creating models that not only predict but also offer strategies to improve performance.</p>
                        </Box>
                        <Box style={{backgroundImage: `url(${AboutImage})`, height: '20rem', width: '30rem', backgroundSize: '150%',backgroundPosition: '20% 50%', borderRadius: '5%', border: 'solid #5c5e5f .1rem'}}></Box>
                    </Box>
                </Box>
            </section>
        </>
    );
};
