import { Home } from "./pages/Home";
import { ComputerVision } from "./pages/ComputerVision";
import { NaturalLanguageProcessing } from "./pages/NaturalLanguageProcessing"
import { Navbar } from "./components/global/Navbar";

import { Route, Routes } from "react-router-dom";
import useScrollToTop from "./ScrollToTop";

import '../src/dist/styles.css';

function App() {
  useScrollToTop();
  return (  
  <>
    <Navbar/>
    <Routes>
      <Route path="/" element = {<Home/>}/>
      <Route path="/cv" element = {<ComputerVision/>}></Route>
      <Route path="/nlp" element = {<NaturalLanguageProcessing/>}></Route>
    </Routes>
    
  </>
  );
}

export default App;
