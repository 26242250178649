import React, { useState } from "react";
import { Box } from "@mui/material";
import Typewriter  from "typewriter-effect";

export const AIHero = () => {


    // const [currentinptText, setcurrentInptText] = useState("");
    const [inptText, setInptText] = useState("");


    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevents page refresh
        // Send inputText to the back-end using fetch
        try {
            const response = await fetch('http://127.0.0.1:5000/generate-comment', {
            method: 'GET',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
            // body: JSON.stringify({seed_word: currentinptText})
            });
            if (response.ok) {
            const text = await response.json();
            console.log('Response from server:', text['sentence']);
            setInptText(text['sentence']);
            } else {
            console.log('Failed to send data');
            // setInptText(currentinptText);
            }
        } catch (error) {
            console.error('Error:', error);
            // setInptText(currentinptText);

        };
    };

    const submitSytle = {
        cursor: 'pointer',
        // 'h5': {
        //     color: typ === 'top_comments' ? 'white' : 'black'
        // },
        '&:hover': {
            border: '1px solid rgb(0, 0, 0)',
            outlineColor: 'rgb(0, 0, 0 , 0)',
            outlineOffset: '15px',
        },
        borderRadius: '1rem',
        height: '2rem',
        width: '10rem',
        border: '1px solid white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        outline: '1px solid',
        outlineColor: 'rgba(0, 0, 0,0.5)',
        outlineOffset: '0px',
        textShadow: 'none',
        backgroundColor: 'white',
        transition: 'all 1250ms cubic-bezier(0.19, 1, 0.22, 1)'
    };

    return (
        <>
        <Box height = '50rem' backgroundColor = 'white' paddingBottom="5rem">
            <Box display="flex" justifyContent="center" textAlign="center" paddingTop="10rem" >
                <h1 style={{'fontWeight':'lighter', fontSize: 'xx-large'}}>AI-Generated YouTube Comments</h1>
            </Box>
            <Box display="flex" justifyContent="center" textAlign="center" alignItems = 'center' paddingTop="5rem" margin="0 auto" maxWidth = '50rem' flexDirection='column'>
                <Box display="flex" textAlign="center" paddingBottom="7rem">
                    <p style={{letterSpacing: '0.05rem', fontWeight: '300', lineHeight: 1.5, color: '#000000', fontSize: '1.2rem'}}>
                    Try out my AI comment generator trained on YouTube comments. It leverages a long-short-term-memory neural network to create novel comments.
                    <br></br>This model was trained on thousands of unique comments.
                    </p>
                </Box>
                <Box display = 'flex' justifyContent="center" paddingBottom="5rem" alignItems='center'>
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" textAlign = 'center' alignItems="center" justifyContent="center" sx={submitSytle} onClick = {handleSubmit}>
                            <h5 style={{fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>Generate Comment</h5>
                        </Box>
                    </form>
                </Box>
                <Box display="flex" justifyContent="space-between" textAlign="left" maxWidth="50rem" height = '5rem' backgroundColor = 'white'>
                    <div>
                        <h1>
                        <Typewriter
                            options={{
                                strings: inptText,
                                loop: false,
                                autoStart: true,
                                delay: 60
                            }}
                            />
                        </h1>
                    </div>
                </Box>
            </Box>
        </Box>
        </>
    );
};