import React from "react";
import { Box } from "@mui/material";
import { IconBrandGithub, IconBrandLinkedin } from "@tabler/icons-react";

export const Footer = () => {
    return (
        <>
            <section id="footer">
                <Box sx={{display: 'flex', width: '100%', height: '10rem', backgroundColor: '#212121', justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx = {{display: 'flex', justifyContent: 'space-between', width: '50rem'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <h3 style={{paddingBottom: '1rem', letterSpacing: '0.05rem', fontWeight: '700', fontSize:"1.4rem", color: 'white'}}>Email</h3>
                            <p style={{letterSpacing: '0.05rem', fontWeight: '300', fontSize:"1.2rem", color: 'white'}}>roshan_gidda@outlook.com</p>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem'}}>
                            <a aria-label="linkedin" rel= 'noreferrer' target = '_blank' href="https://www.linkedin.com/in/roshan-gidda-059b392a9" style={{color: 'white'}}>
                                <IconBrandLinkedin/>
                            </a>
                            <a aria-label="github" rel= 'noreferrer' target = '_blank' href="https://github.com/XXRG456" style={{color: 'white'}}>
                                <IconBrandGithub/>
                            </a>
                        </Box>
                    </Box>
                </Box>
            </section>
        </>
    );
};