import React from "react";
import { ResponsiveBoxPlot } from "@nivo/boxplot";

export const MyBoxPlot = ({ data }) => {
    return (
        <>
            <ResponsiveBoxPlot
            minValue={0}
            maxValue={1}
            colors={['#8fff95', '#ff7b7b', '#dfdfdf']}
            enableLabel={false} 
            subGroupBy="subgroup"
            padding={0.35}
            innerPadding={10}
            enableGridX={true}
            valueFormat=">.2f"
            axisTop={{
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                truncateTickAt: 0
            }}
            margin={{ top: 60, right: 140, bottom: 75, left: 60 }}
            data={ data }
            theme={{
                'axis': {
                    'legend': {
                        'text': {
                            'fontSize': '16px'
                        }
                    }
                }
            }}
            axisRight={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 0,
                truncateTickAt: 0
            }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 15,
                tickRotation: 0,
                legend: 'Video ID',
                legendPosition: 'middle',
                legendOffset: 60,
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Sentiment Score',
                legendPosition: 'middle',
                legendOffset: -50,
                truncateTickAt: 0
            }}
            borderRadius={2}
            borderWidth={2}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.3
                    ]
                ]
            }}
            medianWidth={2}
            medianColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.3
                    ]
                ]
            }}
            whiskerEndSize={0.6}
            whiskerColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.3
                    ]
                ]
            }}
            motionConfig="stiff"
            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemWidth: 60,
                    itemHeight: 20,
                    itemsSpacing: 3,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    symbolSize: 20,
                    symbolShape: 'square',
                    data: [
                        {'id': 'roberta_pos', 'label': 'Positive', 'color': '#8fff95'},
                        {'id': 'roberta_neu', 'label': 'Neutral', 'color': '#dfdfdf'},
                        {'id': 'roberta_neg', 'label': 'Negative', 'color': '#ff7b7b'}
                    ],
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]} 
            />
        </>
    );
}