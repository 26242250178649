import { React, useState } from "react";
import { IconX } from '@tabler/icons-react';
import { scrollToSection } from "../../ScrollToId";
export const Sidebar = () => {



    const [sidebar, setSidebar] = useState(true);
    
    const sidebarMenu = () => {
        setSidebar(!sidebar);
    };

    return (
        <>
        <div className={ sidebar ? 'sidebar active' : 'sidebar'}>
            <span className={ sidebar ? 'sidebar-close-icon' : 'sidebar-close-icon active'} onClick={() => sidebarMenu()}><IconX></IconX></span>
            <div className="sidebar-content">
                <ol>
                    <li>
                        <a href="#what-face-recog" onClick={scrollToSection}> What is face recognition?</a>
                        <ul>
                            <li>
                                <a href="#goal" onClick={scrollToSection}> goal </a>
                            </li>
                            <li>
                                <a href="#process" onClick={scrollToSection}> process </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#what-siamese-model" onClick={scrollToSection}>What is a Siamese model?</a>
                        <ul>
                        <li>
                            <a href="#structure" onClick={scrollToSection}>Structure</a>
                        </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#training" onClick={scrollToSection}>Training</a>
                        <ul>
                            <li>
                                <a href="#input-pairs" onClick={scrollToSection}>Input pairs</a>
                            </li>
                            <li>
                                <a href="#inspect-pairs" onClick={scrollToSection}>Inspect pairs</a>
                            </li>
                            <li>
                                <a href="#loss-func" onClick={scrollToSection}>Loss function</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#siamese-model" onClick={scrollToSection}>Siamese Model</a>
                        <ul>
                            <li>
                                <a href="#train-model" onClick={scrollToSection}>Train model</a>
                            </li>
                            <li>
                                <a href="#visualise" onClick={scrollToSection}>visualise results</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#evaluation" onClick={scrollToSection}>Evaluation</a>
                        <ul>
                            <li>
                                <a href="#prec-recall" onClick={scrollToSection}>Precision and recall</a>
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
        <div className={ sidebar ? 'sidebar-menuicon active' : 'sidebar-menuicon'} onClick={() => sidebarMenu()}></div>
        </>
    );
};