import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import NLPImage from '../../images/sentiment-analysis-pic.jpeg';
import CVImage  from "../../images/face-recognition-image.jpeg";
import { IconBrandGithub } from "@tabler/icons-react";

export const Projects = () => {

    const projectSytle = {
        transition: 'all ease-in-out 0.3s',
        '&:hover': {
            transform: 'translateY(-1rem)'
        }
    };

    return (
        <>
            <section style={{display: 'flex', backgroundColor: '#f9f9f9'}} id='projects' href = '#projects'>
                <Box sx={{margin: '0 auto', maxWidth: '80rem', padding: '10rem 0 10rem 0'}}>
                    <h3 style={{paddingBottom: '5rem', letterSpacing: '0.05rem', fontWeight: '700', fontSize:"1.2rem", color: '#555555', textTransform: 'uppercase', textAlign:'center'}}>projects</h3>
                    <Box sx={{display: 'grid', alignItems: 'center', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', gap: '5rem'}}>
                        <Box sx={{display: 'flex', justifyContent:'end', alignContent: 'end'}}>
                            <Link to={'/nlp'}>
                                <Box sx={{backgroundImage: `url(${NLPImage})`, height: '20rem', width: '30rem' ,backgroundSize: '130%',backgroundPosition: '50% 50%', borderRadius: '5%', border: 'solid #5c5e5f .1rem',transition: 'transform 0.3s ease','&:hover': {transform: 'translateY(-1rem)',},}}>
                                    
                                </Box>
                            </Link>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <h4 style={{color: '#2d2e32', fontSize: '2.5rem', lineHeight: 1.5}}>Sentiment Analysis</h4>
                            <p style={{letterSpacing: '0.05rem', fontWeight: '300', lineHeight: 1.5, color: '#000000', fontSize: '1.2rem', paddingTop: '1rem'}}>Explore my interactive YouTube sentiment analysis dashboard, which compares sentiment trends between the latest and top comments. 
                            </p>
                            <p style={{letterSpacing: '0.05rem', fontWeight: '300', lineHeight: 1.5, color: '#000000', fontSize: '1.2rem', paddingTop: '1rem'}}>Take a look at the data and see how sentiment shifts between influencers using state-of-the-art NLP models.</p>
                            <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', alignItems: 'center', gap: '1rem', paddingTop: '2rem'}}>
                                <h3 style={{letterSpacing: '0.05rem', fontWeight: '700', fontSize:"1.2rem", color: '#555555', textTransform: 'uppercase'}}>See code</h3>
                                <a aria-label="github" rel= 'noreferrer' target = '_blank' href="https://github.com/XXRG456/youtube_api" style={{color: 'black'}}>
                                    <IconBrandGithub/>
                                </a>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', paddingTop: '5rem'}}>
                            <h4 style={{color: '#2d2e32', fontSize: '2.5rem', lineHeight: 1.5}}>Face Recognition System</h4>
                            <p style={{letterSpacing: '0.05rem', fontWeight: '300', lineHeight: 1.5, color: '#000000', fontSize: '1.2rem', paddingTop:'1rem'}}>Test out my celebrity face recognition system, powered by a Siamese deep neural network. 
                            </p>
                            <p style={{letterSpacing: '0.05rem', fontWeight: '300', lineHeight: 1.5, color: '#000000', fontSize: '1.2rem', paddingTop:'1rem'}}>This model excels at recognising faces by learning subtle differences between face features, delivering accurate and efficient results.</p>
                            <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', alignItems: 'center', gap: '1rem', paddingTop: '1rem'}}>
                                <h3 style={{letterSpacing: '0.05rem', fontWeight: '700', fontSize:"1.2rem", color: '#555555', textTransform: 'uppercase'}}>See code</h3>
                                <a aria-label="github" rel= 'noreferrer' target = '_blank' href="https://github.com/XXRG456/facerecognition" style={{color: 'black'}}>
                                    <IconBrandGithub/>
                                </a>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', paddingTop: '5rem'}}>
                            <Link to={'/cv'}>
                                <Box sx={{backgroundImage: `url(${CVImage})`, height: '20rem', width: '30rem', backgroundSize: '100%',backgroundPosition: '50% 50%', borderRadius: '5%', border: 'solid #5c5e5f .1rem',...projectSytle}}>
                                    
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </section>
        </> 
    );
};