
export function scrollToSection(event) {
  event.preventDefault();
  const id = event.target.getAttribute('href').substring(1);
  const offset = 150;
  const element = document.getElementById(id);
  if (element) {
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };
};



  
