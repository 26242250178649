import { Link } from 'react-router-dom';

function Handle() {

    return window.scrollTo({ top: (0,0), behavior: "smooth"});
}

export const Navbar = () => {


    return (
   <>     
    <nav>
        <h3 onClick={Handle} className="title">
            Roshan.Gidda
        </h3>
        <ul>
            <li>
                <Link to = "/">
                    Home
                </Link>
            </li>
            <li>
                <Link to='/#projects'>
                    Projects
                </Link>
            </li>
            <li>
                <Link to='/#about'>
                    About
                </Link>
            </li>
        </ul>
    </nav>
    </>
    );
};