import { React, useState} from "react";
import { LineChart } from "./LineChartKDE";
import { MyBoxPlot } from "./BoxPlot";
import { MyBarChart } from "./BarChart";
import { MyRadarChart } from "./RadarChart";
import mappings from "../../data/influencer_video_mappings.json";
import box_data from "../../data/formattedData_box.json";
import kde_data from "../../data/formattedData_kde.json";
import bar_data from "../../data/formattedData_bar.json";
import stats_data from "../../data/formattedData_stats.json"
import { Box } from "@mui/material";


export const DashBoard = () => {

    const influencers = [{'id':0 , 'influencer': 'Sidemen'},{'id': 1, 'influencer':'BetaSquad'} ,{'id':2, 'influencer': 'Jynxzi'},{'id':3, 'influencer': 'JoeFazer'}];

    const gridsections = {'parameters':{'id': 'params'}, 'frequency_graph': {'id':'freq_graph'}, 'box_plot':{'id':'bxpl'}, 'influencers':{'id':'infr'}, 'kde_plots': {'id':'kde'}, 'radar_graph':{'id':'rd'}}

    const [influencer, SetInfluencer] = useState("Sidemen");
    const [typ, setTyp] = useState("top_comments");

    const [isToggled, setToggle] = useState(true);

    const sentiment = isToggled ? 'positive' : 'negative';
    
    const topSytle = {
        cursor: 'pointer',
        // 'h5': {
        //     color: typ === 'top_comments' ? 'white' : 'black'
        // },
        '&:hover': {
            border: '1px solid rgb(0, 0, 0)',
            outlineColor: 'rgb(0, 0, 0 , 0)',
            outlineOffset: '15px',
        },
        borderRadius: '1rem',
        border: typ === 'top_comments' ? '1px solid rgb(0, 0, 0)' : '1px solid white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        outline: '1px solid',
        outlineColor: typ === 'top_comments' ? 'rgb(0, 0, 0, 0)' : 'rgba(0, 0, 0,0.5)',
        outlineOffset: typ === 'top_comments' ? '15px' : '0px',
        textShadow: 'none',
        backgroundColor: typ === 'top_comments' ? 'white' : 'white',
        transform: typ === 'top_comments' ? 'scale(1.1)' : 'scale(1)',
        transition: 'all 1250ms cubic-bezier(0.19, 1, 0.22, 1)'
    };
    const baseinfluencerStyle = {
        cursor: 'pointer',
        borderRadius: '100%',
        border: '4px solid white',
        '&:hover': {
            borderColor: '#81d4fa',
            transform: 'scale(1.1)'
        },
        transition: 'all 0.3s'
    }
    const influencerStyle = (key) => {
        return {
            ...baseinfluencerStyle,
            borderColor: influencer === key ? '#81d4fa' : 'white',
            transform: influencer === key ? 'scale(1.1)' : 'scale(1)'
        };
    };
    const newSytle = {
        cursor: 'pointer',
        // 'h5': {
        //     color: typ === 'newest_comments' ? 'white' : 'black'
        // },
        '&:hover': {
            border: '1px solid rgb(0, 0, 0)',
            outlineColor: 'rgb(0, 0, 0 , 0)',
            outlineOffset: '15px',
        },
        borderRadius: '1rem',
        border: typ === 'newest_comments' ? '1px solid rgb(0, 0, 0)' : '1px solid white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        outline: '1px solid',
        outlineColor: typ === 'newest_comments' ? 'rgb(0, 0, 0, 0)' : 'rgba(0, 0, 0,0.5)',
        outlineOffset: typ === 'newest_comments' ? '15px' : '0px',
        textShadow: 'none',
        backgroundColor: typ === 'newest_comments' ? 'white' : 'white',
        transform: typ === 'newest_comments' ? 'scale(1.1)' : 'scale(1)',
        transition: 'all 1250ms cubic-bezier(0.19, 1, 0.22, 1)'
    };
    const freqStyle = {
        cursor: 'pointer',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
        borderRadius: '1rem',
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative', 
        letterSpacing: '.10rem',
        textAlign:'center',
        transition: 'all 0.3s',
        overflow: 'hidden',
        zIndex: '1',
        '&:after': {
            content: `''`,
            bottom:'0',
            left: '0',
            position: 'absolute',
            width:'100%',
            height: '100%',
            backgroundColor: sentiment === 'positive' ? '#a8f8a7' : '#eea3a3',
            borderRadius:'1rem',
            zIndex: '-2'
        },
        '&:before': {
            content :`''`,
            bottom: '0',
            left: '0',
            position: 'absolute',
            width: '0%',
            height: '100%',
            backgroundColor: sentiment === 'negative' ? '#a8f8a7' : '#eea3a3',
            transition: 'all .3s',
            borderRadius: '1rem',
            zIndex: '-1'
        },
        '&:hover': {
            color: 'black',
            '&:before': {
                width:'100%'
            }
        }
    };
    const radarStyle = {
        backgroundColor: '#ffffff',
        boxShadow:'0px 4px 12px rgba(0, 0, 0, 0.1)',
        display:"flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
        borderRadius:"1rem"
    };
    const hoverStyle = {
        position:"absolute",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        height:'auto',
        p:1,
        bgcolor:"white",
        borderRadius:"1rem",
        boxShadow:3,
        zIndex:999
    };
    const circleStyle = (color) => ({
        display:'inline-block',
        width:'12px',
        height:'12px',
        backgroundColor: color,
    });


    const [hovered, setHovered] = useState(null);
    // const [mousePos, setMousePos] = useState({x: 0, y: 0});

    const video_ids = mappings[influencer];
    const data_kde = kde_data[influencer][typ];

    // const handleMouseMove = (event) => {
    //     setHovered(gridsections.parameters.id)
    //     setMousePos({
    //         x: event.clientX,
    //         y: event.clientY,
    //     });
    // };

    let data_box = [];
    video_ids.forEach(id => {
        data_box = data_box.concat(box_data[influencer][id][typ]);
    });

    return (
        <>
            <Box paddingTop = "10rem" backgroundColor = '#f9f9f9' paddingBottom="10rem">
                <Box display="flex" justifyContent="center" textAlign="center">
                    <h1 style={{'fontWeight':'lighter', fontSize: 'xx-large'}}>Sentiment analysis</h1>
                </Box>
                <Box display="flex" flexDirection="column" paddingTop="5rem" justifyContent="center" maxWidth="42rem" margin="0 auto" alignItems="center">
                    {/* <h1 style={{textAlign: 'center'}}>Overview</h1> */}
                    <p style={{textAlign: 'center', paddingTop: '2rem', fontSize: "1.2rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}> Can the sentiment between the top comments differ significantly from the newest comments? Are there any patterns for the influencers? 
                    </p>
                    <p style={{textAlign: 'center', paddingTop: '2rem', fontSize: "1.2rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>Interact with the dashboard below to find out:</p>
                </Box>
                {/* Row 1 */}
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(21, 1fr)"
                    gridAutoRows="5rem"
                    
                    gap="1rem"
                    marginTop="7rem"
                    marginLeft="14rem"
                    marginRight="14rem"
                    >
                    

                    {/* TESTING LAYOUTS OF CHARTS START */}

                    {/* ROW 1 */}
                        <Box gridColumn="span 6" gridRow="span 1" display="flex" flexDirection = 'column' alignItems="center" justifyContent="center" boxShadow='0px 4px 12px rgba(0, 0, 0, 0.1)' backgroundColor = '#ffffff' borderRadius="1rem" onMouseEnter={() => setHovered(gridsections.parameters.id)} onMouseLeave = {() => setHovered(null)}>
                        
                            <Box display="grid" gridAutoColumns="1fr 1fr 1fr" gridAutoRows="1fr" gap = "1rem" height="90%" width='90%' alignItems="center">
                                <Box  height = '50%' gridColumn="1"  display="flex" textAlign = 'center' alignItems="center" justifyContent="center" backgroundColor = '#ffffff' onClick={() => setTyp('top_comments')} sx = {topSytle}>
                                    <h5 style={{fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>
                                        Top comments
                                    </h5>
                                </Box>
                                <Box  height = '50%' gridColumn="2" textAlign = 'center' display="flex" alignItems="center" justifyContent="center" backgroundColor = '#ffffff' onClick={() => setTyp('newest_comments')} sx = {newSytle}>
                                    <h5 style={{fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>
                                        Newest comments
                                    </h5>
                                </Box>
                                <Box height = '50%'  gridColumn="3" display="flex" alignItems="center" justifyContent="center" textAlign="center">
                                    <Box sx={freqStyle} width="100%" height="100%" onClick= {() => setToggle(!isToggled)}>
                                        <h6>
                                            Word frequency type
                                        </h6>
                                        
                                    </Box>
                                </Box>
                                {hovered === gridsections.parameters.id && (
                                    <>
                                        <Box sx={{...hoverStyle, bottom: "88%", left: "12%", width:'25rem'}}>
                                            <h2 style={{textAlign: 'center', fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>Select Parameters</h2>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}><strong>Top comments:</strong> Sort by the most popular comments, showcasing the most likely viewed opinions at the time of the API call.</p>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}><strong>Newest comments:</strong> Sort by the latest comments, showcasing sentiment feeling at the time of the API call.</p>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}><strong>Word frequency type:</strong> Sort frequency graph by most frequent positive or negative words for all videos.</p>
                                        </Box>
                                    </>
                                    
                                )}
                            </Box>
                        </Box>
                        <Box gridColumn = 'span 12' gridRow="span 5" boxShadow='0px 4px 12px rgba(0, 0, 0, 0.1)' backgroundColor = '#ffffff' display="flex" flexDirection = 'column' alignItems="center" justifyContent="center" borderRadius="1rem" onMouseEnter={() => setHovered(gridsections.box_plot.id)} onMouseLeave = {() => setHovered(null)}>
                            <h4 style={{textAlign: 'center', lineHeight: 1.5, letterSpacing: ".05rem"}}>Box plot per video</h4>
                            <Box height="90%" width="90%">
                                <MyBoxPlot data={ data_box }></MyBoxPlot>
                            </Box>
                            {hovered === gridsections.box_plot.id && (
                                    <>
                                        <Box sx={{...hoverStyle, bottom: "88%", left: "40%", width: "35rem"}}>
                                            <h2 style={{textAlign: 'center', fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>Box Plot</h2>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}> <strong>LLM used:</strong> ROBERTa</p>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}><strong>Overview:</strong> Each comment has a <span style={circleStyle('#8fff95')}></span> positive, <span style={circleStyle('#dfdfdf')}></span> neutral and <span style={circleStyle('#ff7b7b')}></span> negative sentiment score between <strong>0</strong> and <strong>1</strong>. <br></br>The higher the score, 
                                            the more confident the prediction.<br></br> 
                                            There are 5 videos per influencer.
                                             You can toggle between influencers and their top or newest comments. <br></br>Current active comment type: <strong>{typ}</strong></p>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}> <span style={circleStyle('#8fff95')}></span> <strong>Positive (roberta_pos):</strong> Shows the distribution of positive sentiment of 100 <strong>{typ}</strong> from a specific video ID.</p>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}> <span style={circleStyle('#dfdfdf')}></span> <strong>Neutral (roberta_neu):</strong> Shows the distribution of neutral sentiment of 100 <strong>{typ}</strong> from a specific video ID.</p>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}> <span style={circleStyle('#ff7b7b')}></span> <strong>Negative (roberta_neg):</strong> Shows the distribution of negative sentiment of 100 <strong>{typ}</strong> from a specific video ID.</p>
                                        </Box>
                                    </>
                                    
                                )}
                        </Box>
                        <Box gridColumn="span 3" gridRow="span 9" boxShadow='0px 4px 12px rgba(0, 0, 0, 0.1)' backgroundColor = '#ffffff' display="flex" flexDirection = 'column' alignItems="center" justifyContent="center" borderRadius="1rem"  onMouseEnter={() => setHovered(gridsections.influencers.id)} onMouseLeave = {() => setHovered(null)}>
                            <Box position="relative" top = '-20px'>
                                <h4 style={{textAlign: 'center', lineHeight: 1.5, letterSpacing: ".05rem"}}>Select Influencer</h4>
                            </Box>
                            <Box display='flex' flexDirection = 'column' height = '80%' justifyContent="space-between">
                                {influencers.map(value => (
                                    <>
                                    <Box sx = {influencerStyle(value.influencer)} key={value.id}>
                                        <div className={value.influencer} onClick={() => SetInfluencer(value.influencer)} key={value.id}></div>
                                    </Box>
                                    </>
                                ))}
                            </Box>
                            {hovered === gridsections.influencers.id && (
                                    <>
                                        <Box sx={{...hoverStyle, bottom: "88%", left: "65%", width: "30rem"}}>
                                            <h2 style={{textAlign: 'center', fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>Select Influencer</h2>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}><strong>Overview:</strong> These are more parameters you can toggle between. 
                                            By selecting different influencers, you can observe different sentiment patterns and correlations.</p>
                
                                        </Box>
                                    </>
                                )}
                        </Box>
                        {/* ROW 2 */}
                        <Box gridColumn = 'span 6' gridRow="span 4" boxShadow='0px 4px 12px rgba(0, 0, 0, 0.1)' backgroundColor = '#ffffff' display="flex" flexDirection = 'column' alignItems="center" justifyContent="center" borderRadius="1rem" onMouseEnter={() => setHovered(gridsections.frequency_graph.id)} onMouseLeave = {() => setHovered(null)}>
                            <h4 style={{textAlign: 'center', lineHeight: 1.5, letterSpacing: ".05rem"}}>Word Frequency Chart</h4>
                            <Box height="90%" width="90%">
                                <MyBarChart data={bar_data[influencer][sentiment][typ]} sentiment={isToggled}></MyBarChart>
                            </Box>
                            {hovered === gridsections.frequency_graph.id && (
                                    <>
                                        <Box sx={{...hoverStyle, bottom: "88%", left: "10%", width: "30rem"}}>
                                            <h2 style={{textAlign: 'center', fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>Word Frequency Graph</h2>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}><strong>Overview:</strong> This graph shows the most frequent words sorted by polarity.</p>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}><strong>Word Frequency Type:</strong> Select this parameter to sort between <span style={circleStyle('#8fff95')}></span> positive and <span style={circleStyle('#ff7b7b')}></span> negative words.</p>
                                        </Box>
                                    </>
                                )}
                        </Box>
                        <Box gridColumn = 'span 6' gridRow="span 4" boxShadow='0px 4px 12px rgba(0, 0, 0, 0.1)' backgroundColor = '#ffffff' display="flex" flexDirection = 'column' alignItems="center" justifyContent="center" borderRadius="1rem" onMouseEnter={() => setHovered(gridsections.kde_plots.id)} onMouseLeave = {() => setHovered(null)}>
                            <h4 style={{textAlign: 'center', lineHeight: 1.5, letterSpacing: ".05rem"}}>Average KDE per Influencer</h4>
                            <Box height="90%" width = "100%">
                                <LineChart data={ data_kde }></LineChart>
                            </Box>
                            {hovered === gridsections.kde_plots.id && (
                                    <>
                                        <Box sx={{...hoverStyle, bottom: "62%", left: "10.5%", width: "30rem"}}>
                                            <h2 style={{textAlign: 'center', fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>KDE Plot</h2>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}> <strong>LLM used:</strong> ROBERTa</p>
                                            <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}><strong>Overview:</strong> This plot shows a kernel density estimate, 
                                            which shows the concentration of sentiment averaged out over 5 videos per influencer. 
                                            You can toggle between influencers and their top and newest comments.</p>
                                        </Box>
                                    </>
                                )}
                        </Box>
                            {influencers.map(value => (
                                <>
                                    <Box gridColumn="span 3" gridRow="span 4" sx={radarStyle} onMouseEnter={() => setHovered(gridsections.radar_graph.id)} onMouseLeave = {() => setHovered(null)}>
                                        <h4 style={{textAlign: 'center', lineHeight: 1.5, letterSpacing: ".05rem"}}>Like-View Ratio</h4>
                                        <Box height="80%" width="90%">
                                            <MyRadarChart data = {stats_data[value.influencer]}></MyRadarChart>
                                        </Box>
                                        <h4 style={{textAlign: 'center', lineHeight: 1.5, letterSpacing: ".05rem"}}>
                                            {value.influencer}
                                        </h4>
                                    </Box>
                                </>
                            ))}
                            {hovered === gridsections.radar_graph.id && (
                                <>
                                    <Box sx={{...hoverStyle, bottom: "64%", left: "45%", width: "30rem"}}>
                                        <h2 style={{textAlign: 'center', fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}>Radar Plot</h2>
                                        <p style={{textAlign: 'left', paddingTop: "1rem", fontSize: "1rem", fontWeight: 300, lineHeight: 1.5, letterSpacing: ".05rem"}}><strong>Overview:</strong> The radar plots show the Like to View ratio per video per influencer.
                                            This is calculated as a percentage. <br></br></p>
                                    </Box>
                                </>
                            )}
                       
                    {/* TESTING LAYOUTS OF CHARTS END */}        
                </Box>
            </Box>
        </>
    );
};