import React, { useEffect } from "react";
import hljs from "highlight.js";
import python from "highlight.js/lib/languages/python";
import "highlight.js/styles/monokai.css";

hljs.registerLanguage('python', python);

const stylecode = {

    borderRadius: '1rem',
    boxShadow: '0px 0px 10px #000000',
    overflow: 'auto',
    fontsize: "200",
};


export const CodeBlock = ({ code }) => {
    useEffect(() => {
        hljs.highlightAll();
    }, []);
    return (
        <>
            <pre className="language-python" style={ stylecode }>
                <code>{ code }</code>
            </pre>
        </>
    );
}