import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { scaleLinear } from "d3-scale";

export const MyBarChart = ({ data, sentiment }) => {

    const colorScale = scaleLinear()
        .domain([0, data.length - 1])
        .range(sentiment ? ['#a8f8a7', '#19fa16'] : ['#fb4646', '#faa8a8'])
        

    const colorArray = data.map((_, index) => colorScale(index));
    return (
        <>
            <ResponsiveBar data = {data}
            margin={{ top: 10, right: 20, bottom: 10, left: 80 }}
            indexBy={"id"}
            keys={['value']}
            padding={0.2}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colorBy="value"
            colors={colorArray}
            layout="horizontal"
            axisBottom={false}
            theme={{'labels': {
                'text': {
                    'fontSize': '16px'
                }
            },'axis': {
                'ticks': {
                    'text': {
                        'fontSize': '16px'
                    }
                }
            }}}
            enableGridY = {false}
            axisLeft={{tickFontSize: 0}}
            labelTextColor= {sentiment ? 'black' : 'white'}
            // enableTotals={true}
            // enableLabel={false} 
            // totalsOffset={10}
            >

            </ResponsiveBar>
        </>
    );
};