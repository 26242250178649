import dataicon from "../../images/4824797.png"
import { IconBrandGithub, IconBrandLinkedin, IconLetterK } from "@tabler/icons-react";



export const Hero = () => {

    return (
        <>
            <section id = "hero" className="hero">
                <div className="container">
                    <div className="content">
                        <div className="hero-main">
                            <div className="hero-image"></div>
                            <div className="hero-text">
                                <h4 style={{color: '#2d2e32', fontSize: '3.8rem', lineHeight: 1.5}}>Data Scientist</h4>
                                <img src={dataicon} className="dataicon" alt="dataicon"></img>
                                <p style={{letterSpacing: '0.05rem', fontWeight: '300', lineHeight: 1.5, color: '#000000', fontSize: '1.2rem'}}>
                                    Hi, my name is Roshan Gidda, an MSc Data Science student at the University of Bath,
                                    passionate about transforming data into practical intelligence.
                                </p>
                                <span>
                                    <a aria-label="linkedin" rel= 'noreferrer' target = '_blank' href="https://www.linkedin.com/in/roshan-gidda-059b392a9">
                                        <IconBrandLinkedin/>
                                    </a>
                                    <a aria-label="github" rel= 'noreferrer' target = '_blank' href="https://github.com/XXRG456">
                                        <IconBrandGithub/>
                                    </a>
                                    <a aria-label="kaggle" rel= 'noreferrer' target = '_blank' href="https://www.kaggle.com/roshangidda">
                                        <IconLetterK/>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="skills-content">
                        <div className="skills-main">
                            <div className="skills-description"><h3 style={{letterSpacing: '0.05rem', fontWeight: '700', fontSize:"1.2rem", color: '#555555', textTransform: 'uppercase'}}>technical skills</h3></div>
                            <div className="skills-box">
                            <div className="skills-icons">
                                <div className="tf"></div>
                                <div className="keras"></div>
                            </div>
                            <div className="skills-icons">
                                <div className="pandas"></div>
                                <div className="numpy"></div>
                            </div>
                            <div className="skills-icons">
                                <div className="matplotlib"></div>
                                <div className="flask"></div>
                            </div>
                            </div>
                            </div>
                    </div>
                </div>
            </section>
        </>
    );
}