import { Hero } from "../components/home/Hero";
import { Projects } from "../components/home/Projects";
import { About } from "../components/home/About";
import { Footer } from "../components/home/Footer";

export const Home = () => {
    return (
        <>
        <Hero/>
        <About/>
        <Projects/>
        <Footer/>
        </>
    )
};