import React from "react";
import { Box } from "@mui/material";

export const DashboardEval = () => {

    // const paraStyle = {
    //     textAlign:'left', 
    //     fontWeight: 'lighter', 
    //     maxWidth: '50rem', 
    //     fontSize: '1.15rem', 
    //     paddingTop: '1.1rem', 
    //     paddingBottom: '1.1rem',
    //     letterSpacing: '0.05rem'
    // };

    const listStyle = {
        paddingLeft: '2rem',
        paddingTop: '0.7rem',
        fontWeight: 'lighter',
        fontSize: '1.14rem',
        maxWidth: '50rem',
        letterSpacing: '0.05rem'
    }

    return (
        <>
            <Box backgroundColor = '#f9f9f9' paddingBottom="10rem">
                <Box display="flex" justifyContent="center" width="60rem" margin="0 auto" alignItems="center" flexDirection='row'>
                    <Box maxWidth="100rem" margin="0 auto" padding = '0 4rem'>
                        <Box display="flex" flexDirection="column" alignItems="center" margin='0 auto' marginTop="9rem" maxWidth="50rem">

                            <h1 style={{'fontWeight':'lighter', fontSize: 'xx-large', paddingBottom: '3rem'}}>Sentiment Evaluation</h1>

                            <Box>
                                <h2 style={{fontWeight: '400', letterSpacing: '0.05rem'}}>Observations:</h2>
                                <ol style={listStyle}>
                                    <li style={{paddingTop: '1rem'}}>
                                        <strong style={{fontWeight: '400'}}>Top Comments:</strong>
                                        <ul style={{listStyleType: 'disc', paddingLeft: '2rem', fontSize: '1.14rem'}}>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                                <strong style={{fontWeight: '400'}}>High Positive Sentiment Density:</strong> Top comments, 
                                                    which are heavily influenced by likes and replies, tend to show a much higher concentration of positive sentiment. 
                                                    These comments stand out because they connect with the majority and match the influencer's style. 
                                            </li>
                                            <li style={{paddingTop: '0.5rem'}}>
                                                <strong style={{fontWeight: '400'}}>Polarity:</strong> In cases where a video triggers strong opinions 
                                                (either positive or negative), 
                                                the top comments often reflect this more intensely, creating a sense of agreement.

                                            </li>
                                        </ul>
                                    </li>
                                    <li style={{paddingTop: '1rem'}}>
                                        <strong style={{fontWeight: '400'}}>Newest Comments:</strong>
                                        <ul style={{listStyleType: 'disc', paddingLeft: '2rem', fontSize: '1.14rem'}}>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                                <strong style={{fontWeight: '400'}}>Neutral to Negative Sentiment Tendency: </strong>  
                                                 Newer comments often appear more varied or skew towards neutral/negative sentiment. 
                                                This could be due to a variety of factors, such as more critical or immediate reactions from viewers or 
                                                those with different views who engage later after initial excitement drops.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                                <h2 style={{fontWeight: '400', letterSpacing: '0.05rem', paddingTop: '3rem'}}>Why is this?</h2>
                                <ol style={listStyle}>
                                    <li style={{paddingTop: '1rem'}}>
                                        <strong style={{fontWeight: '400'}}>Observability:</strong>
                                        <ul style={{listStyleType: 'disc', paddingLeft: '2rem', fontSize: '1.14rem'}}>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                           Top comments are the default view for most users, so those seeking social proof tend to read the most engaged comments first, 
                                           regardless of sentiment. The more visible a comment is, the more likely it is that other viewers will like it or reply with their own opinion.
                                            </li>
                                        </ul>
                                    </li>
                                    <li style={{paddingTop: '1rem'}}>
                                        <strong style={{fontWeight: '400'}}>Recency Bias:</strong>
                                        <ul style={{listStyleType: 'disc', paddingLeft: '2rem', fontSize: '1.14rem'}}>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                            Newer comments capture the immediate, unfiltered reactions of viewers, 
                                            which are more diverse. The range of opinions, whether more critical, neutral, 
                                            or even hateful is likely due to the fact that later commenters may not feel the same initial excitement 
                                            with the sentiment that led to the popularity of top comments.
                                            </li>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                            These comments may also reflect changing sentiments over time, 
                                            as viewers have had more time to process the video and share critical or alternative opinions.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                                <h2 style={{fontWeight: '400', letterSpacing: '0.05rem', paddingTop: '3rem'}}>Additional considerations</h2>
                                <ol style={listStyle}>
                                    <li style={{paddingTop: '1rem'}}>
                                        <strong style={{fontWeight: '400'}}>Sample Bias:</strong>
                                        <ul style={{listStyleType: 'disc', paddingLeft: '2rem', fontSize: '1.14rem'}}>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                                The sentiment scores in this analysis are based on a relatively small sample of comments per influencer.
                                                As a result, these scores may not represent the entire sentiment feeling for each video, furthermore potentially
                                                hiding other significant patterns and correlations. 
                                            </li>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                                <strong style={{fontWeight: '400'}}>Top comments:</strong> The characteristics of top comments indicate that they remain
                                                relatively the same over time. Once a comment reaches the top, it tends to stay there unless another comment surpasses it in
                                                popularity. 
                                            </li>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                                <strong style={{fontWeight: '400'}}>Newest comments:</strong> In contrast, newest comments are constantly changing 
                                                as more users view the video. This introduces a more dynamic element to the analysis. One sample of newest comments 
                                                may show a completely different sentiment compared to another sample. This variability could skew conclusions.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>

                                <h2 style={{fontWeight: '400', letterSpacing: '0.05rem', paddingTop: '3rem'}}>Overcoming biases</h2>
                                <ol style={listStyle}>
                                    <li style={{paddingTop: '1rem'}}>
                                        <strong style={{fontWeight: '400'}}>Sample Bias:</strong>
                                        <ul style={{listStyleType: 'disc', paddingLeft: '2rem', fontSize: '1.14rem'}}>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                                To mitigate the effects of sample bias, it would be ideal to analyse a larger dataset
                                                of comments, over a longer period of time. Increasing the the sample size would provide
                                                a more comprehensive view of sentiment trends and may even reveal more patterns that are
                                                 not immediately obvious in smaller samples.
                                            </li>
                                            <li style={{paddingTop: '0.5rem', fontSize: '1.14rem'}}>
                                                <strong style={{fontWeight: '400'}}>Introduce time:</strong> One approach to address 
                                                the variability in newest comments is to take samples at multiple time intervals. 
                                                By comparing sentiments across different time periods, 
                                                we can track sentiment change and reduce the risk of interpreting a single snapshot as 
                                                a representation of the overall sentiment.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </Box>
                                
                        </Box>
                    </Box>
                </Box>
            </Box>           
        </>
    );
};