import React from "react";

export const CelebrityTable = ({ data }) => {


    return (
        <>
            <table className="celebrity-table" id="celebrity-table">
                <thead>
                    <tr>
                        <th>Celebrity</th>
                        <th>Distance</th>
                    </tr>
                </thead>
                <tbody>                               
                    {Object.keys(data).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>
                                {row}
                            </td>
                            <td>
                                {data[row]}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}