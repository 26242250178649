import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from "react";
import { CelebrityTable } from './CelebrityTable';

export const CelebrityHero = () => {

    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [predictions, setPredictions] = useState(null);
    const [rejected, setRejected] = useState(false);
    const [predicted, setPredicted] = useState("");


    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {

        //if (loading) return;

        setPredictions(null);
        setPredicted("");
        setLoading(true);
        setPreview(null);

        rejectedFiles.forEach((element) => {

            setPreview(null);
            setPredicted("");
            setLoading(false);
            setPredictions(null);
            setRejected(true);
            console.log("Rejected Image")

        });

        acceptedFiles.forEach((element) => {
            setRejected(false);
            
            const previewURL = URL.createObjectURL(element);

            setPreview(previewURL);

            const reader = new FileReader()
    
            reader.onload = () => {
                const binaryStr = reader.result;
                const image_format = element.type;
                console.log("Uploading image...");
                uploadImage(binaryStr, image_format);
            };
    
            reader.readAsDataURL(element);
        });

    }, []);

    
    const uploadImage = async (bs64, image_format) => {
        try {
            const response = await fetch("http://127.0.0.1:5000/image-data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    image: bs64,
                    format: image_format })
            });

            const data = await response.json();


            setLoading(false);
            console.log('Server response: ', data);

            const entries = Object.entries(data);

            entries.sort((a, b) => a[1] - b[1]);

            const sortedData = Object.fromEntries(entries); 

            setPredictions(sortedData);

            const first = Object.keys(sortedData)[0].toLowerCase();
            setPredicted(first);
            console.log(first);

        } catch (error) {
            console.error("Error uploading the image:", error);
            setLoading(false);
        }
    };
    
    const {
        getRootProps,
        getInputProps,
        isDragActive,
      } = useDropzone({ onDrop, maxFiles: 1, accept: {'image/jpeg': ['.jpeg', '.jpg'],},
    });

    
    return (
        <section id="computervision" className="computervision">
            <div className="cv-container">
                <div className="cv-content">
                    <h1>Face recognition</h1>
                    <div className='cv-content-pictures'>    
                        <div className="cv-content-column">
                            <div className="ronaldo" id='ronaldo'></div>
                            <h2>Ronaldo</h2>
                        </div>
                        <div className="cv-content-column">
                            <div className="messi" id='messi'></div>
                            <h2>Messi</h2>
                        </div>
                        <div className="cv-content-column">
                            <div className="drake" id='drake'></div>
                            <h2>Drake</h2>
                        </div>
                        <div className="cv-content-column">
                            <div className="eminem" id='eminem'></div>
                            <h2>Eminem</h2>
                        </div>
                    </div> 
                </div>
            </div>
            <div className='cv-container'>
                <div className='cv-predictions'>
                    <div className="cv-content-dropzone" {...getRootProps()}>
                        <input {...getInputProps()}/>
                        {loading ? (
                            <>
                            <img src={preview} alt='loading' className='preview'></img>
                            </>
                        ) : (
                        isDragActive ? (
                            <p>Drop image here ...</p>
                            ) : (
                            rejected ? (
                                <p>Please upload jpeg/jpg image</p>
                            ) : (
                                <p>Drag and drop image here, or click to select image <strong>(jpeg/jpg)</strong></p>
                            )    
                            )
                        )}
                    </div>
                    {predictions && (
                        <>
                        <div className={predicted}></div>
                        <CelebrityTable data={predictions}></CelebrityTable>
                        </> 
                        )}
                </div>
            </div>
        </section>
    );
}