import React from "react";
import { ResponsiveLine } from "@nivo/line";


export const LineChart = ({ data }) => {
    return (
        <>
            <ResponsiveLine
                data={ data }
                colors={['#ff7b7b', '#dfdfdf', '#8fff95']}
                enablePoints = { false }
                margin={{ top: 40, right: 110, bottom: 60, left: 60 }}
                xScale={{ type: 'linear', min: 'auto', max: 'auto'}}
                yScale={{ type: 'linear', stacked: false, max: 2.2 }}
                axisBottom={{
                    orient: 'bottom',
                    legend: 'polarity score',
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    legend: 'density',
                    legendOffset: -40,
                    legendPosition: 'middle'
                }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                xFormat={(value) => `Polarity Score  ${value.toFixed(2)}`}
                yFormat={(value) => `Density  ${value.toFixed(2)}`}
                pointLabelYOffset={-12}
                enableTouchCrosshair={true}
                useMesh={true}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            >

            </ResponsiveLine>
        </>
    );
};
        
