import React from "react";
import { ResponsiveRadar } from "@nivo/radar";

export const MyRadarChart = ({ data }) => {
    return (
        <>
            <ResponsiveRadar 
            data = { data }
            keys = {['ratio']}
            indexBy = {['video']} 
            margin={{ top: 0, right: 10, bottom: 0, left: 10 }}
            borderColor={{ from: 'color' }}
            maxValue={10}
            valueFormat=">-.2f"
            gridLabel={value => null}
            dotSize={10}
            dotColor={{ 'from': 'color' }}
            dotBorderWidth={2}
            blendMode="multiply"
            motionConfig="wobbly"
            colors={'#1689a8'}
            fillOpacity={1}
            >

            </ResponsiveRadar>
        </>
    );
};