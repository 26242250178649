export const codepairs = `
"""
args: None
params: self.class_combinations: list[str] - contains combinations of 2 possible classes

"""
# function creats pairs of images for training or validation set.
pairs = []
pairs_labels = []
# iterate over combinations of classes. (total 10 combinations)
for class_pairs in self.class_combinations:
    # catch matching classes
    if class_pairs[0] == class_pairs[1]:
        # store current class 
        clas = class_pairs[0]
        # number of examples  
        batch = self.IMAGES[clas][typ].shape[0]
        # index all combinations of pairs 
        combinations = [[i, j] for i,j in itertools.combinations(range(batch),2)]
        random.shuffle(combinations)
        # iterate over the randomly shuffled combinations defined by ratio number so
        # dataset has even distribution of labels
        for i in combinations[:self.zeros_ratio[typ]]: # typ: Train | Val
            # add matching example 
            pairs.append(self.IMAGES[clas][typ][i])
            pairs_labels.append(0)
    else:
        # catch non-matchin classes
        clas_1, clas_2 = class_pairs[0], class_pairs[1]
        batch = min(self.IMAGES[clas_1][typ].shape[0], self.IMAGES[clas_2][typ].shape[0])
        combinations = [[i, j] for i,j in itertools.combinations(range(batch), 2)]
        random.shuffle(combinations)
        for i in combinations[:self.ones_ratio[typ]]:
            # add non-matchin example 
            pair = np.array([self.IMAGES[clas_1][typ][i[0]], self.IMAGES[clas_2][typ][i[1]]])
            pairs.append(pair)
            pairs_labels.append(1)
`;

export const loss_func = `
class MyContrastiveLoss(tf.keras.losses.Loss):
    
    def __init__(self, margin = 1, **kwargs):

        super().__init__(**kwargs)
        self.margin = margin
        
    def call(self, y_true, y_pred):

        sqr_pred = K.square(y_pred)
        margin_sqr = K.square(K.maximum(self.margin - (y_pred), 0))

        return K.mean((1 - y_true) * sqr_pred + (y_true) * margin_sqr)
`;

export const codemodel_1 = `
import tensorflow as tf
import keras.backend as K

def ConvBlock(x, filters, kernel_size):
    
    x = tf.keras.layers.Conv2D(filters = filters, 
                           kernel_size = kernel_size, 
                           activation = 'relu',
                           )(x)

    x = tf.keras.layers.MaxPooling2D((2, 2))(x)
    
    return x

def FullyConnected(x):

    x = tf.keras.layers.Conv2D(256, 3, activation = 'relu')(x)
    x = tf.keras.layers.Flatten()(x)
    x = tf.keras.layers.Dense(1024, activation = 'relu')(x)
    
    return x

def BaseNetwork(x):

    x = ConvBlock(x, filters = 64, kernel_size = 10)
    x = ConvBlock(x, filters = 128, kernel_size = 7)
    x = ConvBlock(x, filters = 256, kernel_size = 7)
    
    x = FullyConnected(x)
    
    return x
`;

export const codemodel_2 = `
def euclidean_distance(vects):

    x, y = vects
    sum_square = K.sum(K.square(x - y), axis=1, keepdims=True)
    
    return K.sqrt(K.maximum(sum_square, K.epsilon()))

def build_model():
    
    inputs = tf.keras.layers.Input(shape = (100, 100, 3))
    embedding_network = tf.keras.Model(inputs, BaseNetwork(inputs), 
                                       name = 'embedding_network')
    
    input_top = tf.keras.layers.Input(shape = (100,100,3), name = 'input_top')
    vect_top = embedding_network(input_top)

    input_bottom = tf.keras.layers.Input(shape = (100,100,3), 
                                         name = 'input_bottom')
    vect_bottom = embedding_network(input_bottom)

    eucli_distance = tf.keras.layers.Lambda(euclidean_distance, 
    output_shape = (1,))([vect_top, vect_bottom])
    normal_layer = tf.keras.layers.BatchNormalization()(eucli_distance)
    output = tf.keras.layers.Dense(1, activation = 'sigmoid')(normal_layer)
    
    model = tf.keras.Model(inputs = [input_top, input_bottom], outputs = output, 
                           name = 'siamese_network')
    
    optimizer = tf.keras.optimizers.Adam(learning_rate = 1e-3)
    loss = MyContrastiveLoss(1)
    model.compile(loss = loss, optimizer = optimizer, metrics = ['accuracy'])
    
    return model
`;

export const codetrainmodel = `
if TRAINING:
    with strategy.scope():

        batch_size = 128
        steps_per_epoch = X_train_1.shape[0] // batch_size
        validation_steps = X_val_1.shape[0] // batch_size

        model = build_model()

        history = model.fit([X_train_1, X_train_2], y_train,
                        validation_data = ([X_val_1, X_val_2], y_val),
                        epochs = 70, batch_size = batch_size, 
                        steps_per_epoch = steps_per_epoch,
                        validation_steps = validation_steps, callbacks = [callback])
`;


export const codeprecrecall = `
from tensorflow.keras.metrics import Recall, Precision

def prec_recal(y_true, y_pred):

    r = Recall()
    p = Precision()

    r.update_state(y_pred, y_true)
    p.update_state(y_pred, y_true)
`;

export const codeevalmodel = `
model = build_model()

model.load_weights(f'/kaggle/working/Siamese/checkpoint_{fold}.h5')
model.trainable = False

val_preds = model.predict([X_val_2, X_val_1], verbose = 0)
val_preds = np.array([1. if pred > 0.5 else 0. for pred in val_preds])

prec_recal(y_val, val_preds)
`;
